import { toast, cssTransition } from "react-toastify";

export const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
});

export const toastCall = (message, severity) => {
  switch (severity) {
    case "success":
      toast.success(message, {
        theme: "dark",
        transition: bounce,
        position: "bottom-left",
      });
      break;

    case "info":
      toast.info(message, {
        theme: "dark",
        transition: bounce,
        position: "bottom-left",
      });
      break;

    case "error":
      toast.error(message, {
        theme: "dark",
        transition: bounce,
        position: "bottom-left",
      });
      break;

    case "warning":
      toast.warning(message, {
        theme: "dark",
        transition: bounce,
        position: "bottom-left",
      });
      break;

    default:
      console.log("default");
      toast.dark(message, {
        transition: bounce,
        position: "bottom-left",
      });
      break;
  }
};
