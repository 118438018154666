import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./App.css";
import ReactTooltip from "react-tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { bounce, toastCall } from "./components/toast";

import "react-tooltip/dist/index.js";

const Admin = React.lazy(() => import("./components/AdminPages/Admin"));
const LoginPage = React.lazy(() => import("./components/login"));
const Volunteer = React.lazy(() =>
  import("./components/VolunteerPages/Volunteer")
);

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 112px)",
    flexGrow: 1,
    //paddingTop: theme.spacing(3),
    //paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: { minHeight: "calc(100vh - 128px)" },
  },
}));

export const GoogleLoginStateContext = React.createContext({
  isSignedIn: false,
  idToken: "",
  role: "",
  loading: false,
  setLoading: () => {},
  setSignedInState: () => {},
});

export const getIdToken = () => {
  const { gapi } = window;
  return gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse()
    .id_token;
};

function App() {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [googleLoginState, setGoogleLoginState] = useState({
    isSignedIn: false,
    idToken: "",
    role: "",
  });
  const { gapi } = window;

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const SCOPES =
    "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid";

  const initGapiClient = async () => {
    await gapi.client
      .init({
        clientId: CLIENT_ID,
        scope: SCOPES,
      })
      .then(
        () => {
          const googleAuth = gapi.auth2.getAuthInstance();
          googleAuth.isSignedIn.listen((isSignedIn) =>
            setGoogleLoginState((prevState) => {
              const currentUserProfile = googleAuth.currentUser
                .get()
                .getAuthResponse();
              if (currentUserProfile.id_token) {
                setLoading(true);
                axios
                  .get(`${process.env.REACT_APP_SERVER_ADDRESS}/permissions`, {
                    headers: {
                      Authorization: `BEARER ${currentUserProfile.id_token}`,
                    },
                  })
                  .then((res) => {
                    if (res.data.admin) {
                      return {
                        ...prevState,
                        isSignedIn,
                        idToken: currentUserProfile.id_token,
                        role: "admin",
                      };
                    } else if (res.data.volunteer) {
                      return {
                        ...prevState,
                        isSignedIn,
                        idToken: currentUserProfile.id_token,
                        role: "volunteer",
                      };
                    }
                    setLoading(false);
                  });
              }
              return {
                ...prevState,
                isSignedIn: isSignedIn,
                idToken: currentUserProfile.id_token,
                role: "",
              };
            })
          );
          const currentUserProfile = googleAuth.currentUser
            .get()
            .getAuthResponse();
          if (currentUserProfile.id_token) {
            setLoading(true);
            axios
              .get(`${process.env.REACT_APP_SERVER_ADDRESS}/permissions`, {
                headers: {
                  Authorization: `BEARER ${currentUserProfile.id_token}`,
                },
              })
              .then((res) => {
                setGoogleLoginState((prevState) => {
                  if (res.data.admin) {
                    return {
                      ...prevState,
                      isSignedIn: googleAuth.isSignedIn.get(),
                      idToken: currentUserProfile.id_token,
                      role: "admin",
                    };
                  } else if (res.data.volunteer) {
                    return {
                      ...prevState,
                      isSignedIn: googleAuth.isSignedIn.get(),
                      idToken: currentUserProfile.id_token,
                      role: "volunteer",
                    };
                  }
                });
                setLoading(false);
              });
          } else {
            setGoogleLoginState((prevState) => {
              return {
                ...prevState,
                isSignedIn: googleAuth.isSignedIn.get(),
                idToken: currentUserProfile.id_token,
                role: "",
              };
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    gapi.load("client:auth2", initGapiClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (googleLoginState.isSignedIn) {
      toastCall("Successfully logged in", "success");
    }
  }, [googleLoginState.isSignedIn]);

  useEffect(() => {
    if (googleLoginState.role !== "") {
      if (googleLoginState.role === "admin") history.push("/admin");
      else if (googleLoginState.role === "volunteer")
        history.push("/volunteer");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleLoginState.role]);

  const Loading = (
    <div className={classes.content}>
      <Backdrop open>
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </div>
  );

  return (
    <Suspense fallback={Loading}>
      <ReactTooltip />
      <Switch>
        <GoogleLoginStateContext.Provider
          value={{ googleLoginState, setGoogleLoginState, loading, setLoading }}
        >
          <div>
            <Backdrop
              className={classes.backdrop}
              unmountOnExit
              style={{ color: "black", zIndex: 1000 }}
              open={loading}
            >
              <CircularProgress style={{ color: "white" }} />
            </Backdrop>
            <Route path="/" exact={true}>
              <LoginPage
                style={{
                  width: "100%",
                  height: 100,
                  maxWidth: 400,
                  border: "1px solid",
                }}
              />
            </Route>
            <Route path="/volunteer" exact={true}>
              <Volunteer />
            </Route>
            <Route path="/admin" exact={true}>
              <Admin />
            </Route>
          </div>
          <ToastContainer transition={bounce} />
        </GoogleLoginStateContext.Provider>
      </Switch>
    </Suspense>
  );
}

export default App;
